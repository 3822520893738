import axios from 'axios'
export default {
  async index() {
    return new Promise((resolve, reject) => {
      axios
        .get('service_plan')
        .then(res => {
          resolve(res.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async create(data) {
    return new Promise((resolve, reject) => {
      axios
        .post('service_plan', data)
        .then(res => {
          resolve(res.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async patch(id, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`service_plan/${id}`, data)
        .then(res => {
          resolve(res.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}